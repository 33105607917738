import { mdiAccountOutline, mdiCalculator, mdiDatabaseOutline } from '@mdi/js'

export default function useUIResolver() {
  const deliveryTerms = [
    '5 days',
    '10 days',
    '15 days',
    '30 days',
    '45 days',
    '60 days',
    '90 days',
    'Refer to the date of activity',
  ]

  const paymentTerms = ['Regular Payment']

  const procurementModes = ['Direct', 'Negotiated']

  const year = new Date().getFullYear()
  const years = Array.from(new Array(15), (v, idx) => year - idx)

  const resolveDate = date => {
    const sameYear = new Date().getFullYear() === new Date(date).getFullYear()
    let options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }

    if (!sameYear) {
      options = { ...options, ...{ year: 'numeric' } }
    }

    return new Date(date).toLocaleDateString('en-us', options)
  }

  /**
   * HEADERS
   */

  const itemHeaders = () => [
    {
      text: 'Description',
      value: 'description',
      width: 150,
      sortable: false,
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const employeeTableHeaders = () => [
    { text: 'NAME', value: 'first_name', width: 250 },
    { text: 'EMAIL', value: 'email', width: 200 },
    { text: 'ROLE', value: 'roles' },
    { text: 'TITLE', value: 'title' },
    {
      text: 'STATUS',
      value: 'is_active',
      width: 100,
      align: 'center',
    },
    {
      text: 'CAN LOGIN',
      value: 'can_login',
      width: 120,
      align: 'center',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const poHeaders = () => [
    {
      text: 'PO No',
      value: 'po_number',
      width: 150,
      sortable: false,
    },
    {
      text: 'PO DATE',
      value: 'po_date',
      sortable: false,
      align: 'center',
      width: 120,
    },
    {
      text: 'PR No',
      value: 'rfq.pr_number',
      width: 150,
      sortable: false,
    },
    {
      text: 'QUOTATION NO',
      value: 'quotation_number',
      width: 150,
      sortable: false,
    },
    {
      text: 'SUPPLIER',
      value: 'supplier.supplier_name',
      width: 150,
      sortable: false,
    },

    {
      text: 'PAYMENT TERM',
      value: 'payment_term',
      width: 100,
      sortable: false,
    },
    {
      text: 'DELIVERY TERM',
      value: 'delivery_term',
      width: 100,
      sortable: false,
    },

    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const supplierHeaders = () => [
    {
      text: 'SUPPLIER',
      value: 'supplier_name',
      width: 150,
      sortable: false,
    },
    {
      text: 'OWNER',
      value: 'owner',
      sortable: false,
      align: 'center',
      width: 120,
    },
    {
      text: 'ADDRESS',
      value: 'address',
      width: 150,
      sortable: false,
    },
    {
      text: 'TEL/CELL',
      value: 'tel_cel',
      width: 100,
      sortable: false,
    },
    {
      text: 'EMAIL',
      value: 'email',
      width: 100,
      sortable: false,
    },

    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const rfqHeaders = () => [
    {
      text: 'QUOTATION #',
      value: 'quotation_number',
      align: 'start',
      width: 150,
    },
    {
      text: 'PR No',
      value: 'pr_number',
      width: 150,
      sortable: false,
    },
    {
      text: 'ABC',
      value: 'abc',
      width: 130,
      sortable: false,
    },
    {
      text: 'OFFICE',
      value: 'office_name',
      width: 150,
      sortable: false,
    },
    {
      text: 'DATE',
      value: 'quotation_date',
      width: 100,
      sortable: false,
    },
    {
      text: 'SUPPLIERS',
      value: 'suppliers',
      width: 70,
      sortable: false,
    },
    {
      text: 'ABSTRACT',
      value: 'abstract',
      width: 70,
      sortable: false,
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 90,
    },
  ]

  const resolveUserRoleVariant = role => {
    if (role === 'Staff') return 'primary'
    if (role === 'App Admin') return 'success'
    if (role === 'Accountant') return 'info'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'Staff') return mdiAccountOutline
    if (role === 'App Admin') return mdiDatabaseOutline
    if (role === 'Accountant') return mdiCalculator

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'

    return 'primary'
  }

  return {
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,

    resolveDate,

    deliveryTerms,
    paymentTerms,
    procurementModes,
    years,

    // headers
    poHeaders,
    itemHeaders,
    supplierHeaders,
    rfqHeaders,
    employeeTableHeaders,
  }
}
